export default {
    advantage: [
        // {
        //     id: 1,
        //     url: require('../../../assets/images/youshi_iocn_1.png'),
        //     title: '针对性强定制研发'
        // },
        // {
        //     id: 2,
        //     url: require('../../../assets/images/youshi_iocn_2.png'),
        //     title: '针对性强定制研发'
        // },
        // {
        //     id: 3,
        //     url: require('../../../assets/images/youshi_iocn_3.png'),
        //     title: '针对性强定制研发'
        // },
        // {
        //     id: 4,
        //     url: require('../../../assets/images/youshi_iocn_5.png'),
        //     title: '针对性强定制研发'
        // },
        // {
        //     id: 5,
        //     url: require('../../../assets/images/youshi_iocn_6.png'),
        //     title: '针对性强定制研发'
        // },
        // {
        //     id: 6,
        //     url: require('../../../assets/images/youshi_iocn_43.png'),
        //     title: '针对性强定制研发'
        // },
    ],
    lunbo: [
        {
            id: 1,
            img_url: ''
        }
    ],
    partners: [
        // {
        //     url: '',
        //     id: 1
        // }, {
        //     url: '',
        //     id: 2
        // }, {
        //     url: '',
        //     id: 3
        // }, {
        //     url: '',
        //     id: 4
        // }, {
        //     url: '',
        //     id: 5
        // }, {
        //     url: '',
        //     id: 6
        // },
    ],
    process: [
        { brief: '', title: '', url: '', id: 1 }
    ]
}