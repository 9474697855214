import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
    data() {
        return {
            swiperOption: {
                direction: "horizontal",
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                autoplay: {
                    delay: 3000,
                    autoplay: true,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                },
            },
        };
    },
    components: {
        swiper,
        swiperSlide,
    },
}