<template>
  <div class="item" @click="toDetail">
    <div class="img-box">
      <img :src="classImg" alt="" />
    </div>
    <div class="info">
      <h5>{{ className }}</h5>
      <!-- <h6>{{ classInfo }}</h6> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    classImg: {
      type: String,
      default:
        "https://www.liuniukeji.com/Uploads/Picture/Image/2018-10-09/58fd8c668821a.jpg",
    },
    className: {
      type: String,
      default: "分类名称",
    },
    classInfo: {
      type: String,
      default: "加工简介加工过简介",
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    toDetail: function () {
      this.$router.push({ path: "/server-detail", query: { id: this.id } });
    },
  },
};
</script>
<style scoped>
.item {
  width: 290px;
  height: 326px;
  margin-right: 12px;
  background: #f7f7f7;
  text-align: center;
  margin-bottom: 16px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}
.item:nth-child(4n) {
  margin-right: 0;
}
.item .img-box {
  width: 100%;
  height: 215px;
}
.item .img-box img {
  display: block;
  width: 100%;
  height: 100%;
}
.item .info {
  box-sizing: border-box;
  padding: 20px;
}
.item .info h5 {
  color: #333;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 700;
}
.item .info h6 {
  color: #666;
  font-size: 12px;
  overflow: hidden;
  margin-top: 10px;
  max-height: 44px;
  line-height: 22px;
}
.item:hover {
  background: #007db8;
}
.item:hover .info h5 {
  color: #fff;
}
.item:hover .info h6 {
  color: #fff;
}
</style>