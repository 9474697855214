<template>
  <div class="i-title">
    <h3>{{ title }}</h3>
    <h4 v-html="subTitle">{{ subTitle }}</h4>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },
};
</script>
<style scoped>
.i-title{
    margin-top: 45px;
}
.i-title h3 {
  height: 37px;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
  text-align: center;
}
.i-title h4 {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
  text-align: center;
}
</style>