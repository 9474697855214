export default {
    data: function () {
        return {
            form: {
                name: '',
                phone: '',
                content: '',
            },
            serviceList:[],
        }
    },
    created() {
        this.getIndex();
        this.getIndexMessage();
        this.getServiceList();
    },
    methods: {
        getServiceList: function () {
            this.$post('/api/index/serviceList.html', {
                page: 1,
                pageSize: 200
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.serviceList = res.data.slice(0,8) || []
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        onSubmit() {
            console.log('submit!');
        },
        getIndex: function () {
            this.$post('/api/index/index.html', {}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.advantage = res.data.advantage.list || [];
                    this.lunbo = res.data.lunbo || [];
                    this.partners = res.data.partners.list || [];
                    this.process = res.data.process.list || [];
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        getIndexMessage: function () {
            this.$post('/api/base/indexMessage.html', {}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.gbMessage = res.data || []
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        moreServe: function () {
            console.log(12312)
            this.$router.push({ path: "/service", query: {} });
        },
        toTzUp: function () {
            this.$router.push({ name: 'TzUpload' })
        },
        toYs: function (id) {
            this.$router.push({ path: "/news-detail", query: { id: id } });
        },
        addDemandInfo: function () {
            this.$post('/api/index/addDemandInfo.html', this.form).then((res) => {
                console.log(res)
                if (res.code == 200) {

                } else {

                }
                return this.$message(res.msg);
            }).finally(() => {

            })
        }
    }
}