<template>
  <div class="index">
    <div class="index-c">
      <div class="swiper-box">
        <swiper :options="swiperOption">
          <swiper-slide v-for="item in lunbo" :key="item.id">
            <img class="s-img" :src="item.img_url" alt="" />
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <div class="notice-box cf">
        <div class="notice-icon fl">
          <img src="../../../assets/images/home_iocn_tongzhi.png" alt="" />
        </div>
        <div class="notice-list fl">
          <el-carousel
            height="60px"
            direction="vertical"
            :autoplay="true"
            indicator-position="none"
          >
            <el-carousel-item v-for="(item,index) in gbMessage" :key="index">
              <h3 class="medium">{{item}}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <iTitle title="我们的加工类型" subTitle="MANUFACTURING TECHNIQUE" />
      <div class="cf class-box">
        <classItem
          class="fl"
          v-for="item in serviceList"
          :key="item.id"
          :classImg="item.url"
          :className="item.title"
          :classInfo="item.brief"
          :id="item.id"
        />
      </div>
      <div class="more-btn" @click="moreServe">
        更多类型<img
          src="../../../assets/images/comon_iocn_jiantou_s.png"
          alt=""
        />
      </div>
      <div class="up-box" @click="toTzUp"></div>
      <iTitle title="我们的优势" subTitle="Our advantage" />
      <div class="adv-list cf">
        <div class="item fl" v-for="item in advantage" :key="item.id">
          <img :src="item.url" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
      <iTitle title="我们的伙伴" subTitle="our business partner" />
      <div class="partner-list">
        <img
          class="partner-logo"
          v-for="item in partners"
          :key="item.id"
          :src="item.url"
          alt=""
          srcset=""
        />
        <img class="bg" src="../../../assets/images/home_hzhb_bg.png" alt="" />
      </div>
    </div>
    <div class="form-box">
      <div class="index-c">
        <h3>填写您的项目需求给我们</h3>
        <el-form ref="form" :model="form" class="inp-box cf">
          <el-input
            class="fl inp"
            v-model="form.name"
            placeholder="称呼姓名"
          ></el-input>
          <el-input
            class="fl inp"
            v-model="form.phone"
            placeholder="联系电话"
          ></el-input>
          <el-input
            class="fl inp last-inp"
            v-model="form.content"
            placeholder="留下您的项目需求及成本预算"
          ></el-input>
          <el-button class="fl btn" type="primary" @click="addDemandInfo"
            >提交需求</el-button
          >
        </el-form>
        <p class="hint">*请认真填写需求信息，我们会在24小时内与您取得联系。</p>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import iTitle from "@/component/i-title/src/i-title";
import classItem from "@/component/class-item/src/class-item";
import iData from "@/views/index/data/iData";
export default {
  mixins,
  components: {
    iTitle,
    classItem,
  },
  data: function () {
    return {
      advantage: iData.advantage,
      lunbo: iData.lunbo,
      process: iData.process,
      partners: iData.partners,
      gbMessage:['','']
    };
  },
};
</script>
<style scoped>
@import "../index.css";
</style>